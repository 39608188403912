@import "../css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/table/lib/css/table.css";
@import "palette.css";
@import "common.css";
@import "fonts.css";
@import "grid.css";

:root {
	/*Fonts*/
	--font-primary: var(--inter);
	--font_primary: var(--inter);

	--font-secondary: var(--inter); /** Used for captions */
	--font_secondary: var(--inter);

	--font-family-button: var(--inter);
	--font_family_button: var(--inter);
}
