/*INPUT*/

/*SMOOTH*/
.smooth[class*="input-group"] input[class*="input"],
.smooth.active.isFilled[class*="input-group"] input[class*="input"] {
  box-shadow: none;
  background: var(--color5);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.smooth[class*="input-group"].active input[class*="input"],
.smooth[class*="input-group"] input[class*="input"]:hover {
  color: var(--color100);
  background: var(--color10);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.smooth[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: initial;
}
.smooth[class*="input-group"] label {
  opacity: 0;
  color: var(--color50);
}
.smooth[class*="input-group"].active.isFilled label,
.smooth[class*="input-group"].active label {
  color: var(--color50);
  opacity: 1;
}
.smooth[class*="input-group"] input[class*="input"]:focus {
  box-shadow: none;
}
/**END SMOOTH*/

/*OUTLINED*/
.outlined[class*="input-group"] input[class*="input"],
.outlined.active[class*="input-group"] input[class*="input"],
.outlined.active.isFilled[class*="input-group"] input[class*="input"] {
  box-shadow: 0 0 0 1px var(--color30);
  background: var(--white);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.outlined[class*="input-group"] input[class*="input"]:hover,
.outlined[class*="input-group"].isFilled input[class*="input"]:hover {
  color: var(--color100);
  box-shadow: 0 0 0 1px var(--color80);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.outlined[class*="input-group"].active input[class*="input"] {
  box-shadow: 0 0 0 2px var(--color80);
}
.outlined[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: initial;
}
.outlined[class*="input-group"] label {
  opacity: 0;
  color: var(--color50);
}
.outlined[class*="input-group"].active.isFilled label,
.outlined[class*="input-group"].active label {
  color: var(--color50);
  opacity: 1;
}
/**END OUTLINED*/

/*RAISED*/
.raised[class*="input-group"] input[class*="input"],
.raised.active[class*="input-group"] input[class*="input"],
.raised.active.success[class*="input-group"] input[class*="input"],
.raised.active.error-helper-text[class*="input-group"] input[class*="input"],
.raised.active.isFilled[class*="input-group"] input[class*="input"] {
  box-shadow: var(--shadow2dp);
  background: var(--white);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.raised[class*="input-group"] input[class*="input"]:hover,
/*.raised[class*="input-group"].success input[class*="input"]:hover,
.raised[class*="input-group"].error-helper-text input[class*="input"]:hover,*/
.raised[class*="input-group"].isFilled input[class*="input"]:hover {
  color: var(--color100);
  box-shadow: var(--shadow4dp);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.raised[class*="input-group"].active input[class*="input"] {
  box-shadow: var(--shadow8dp);
}
.raised[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: initial;
}
.raised[class*="input-group"] input[class*="input"]:hover::placeholder {
  color: var(--color60);
}
.raised[class*="input-group"] label {
  color: var(--color50);
  opacity: 0;
}
.raised[class*="input-group"].active.isFilled label,
.raised[class*="input-group"].active label {
  color: var(--color50);
  opacity: 1;
}
/**END RAISED*/

/*FILLED*/
.filled[class*="input-group"] input[class*="input"],
.filled.active[class*="input-group"] input[class*="input"],
.filled.active.isFilled[class*="input-group"] input[class*="input"] {
  box-shadow: none;
  background: var(--color30);
  color: var(--color0);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.filled[class*="input-group"] input[class*="input"]:hover,
.filled[class*="input-group"].isFilled input[class*="input"]:hover {
  color: var(--color0);
  box-shadow: none;
  background: var(--color40);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.filled[class*="input-group"].active input[class*="input"] {
  box-shadow: none;
  background: var(--color70);
}
.filled[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color0);
  opacity: 1;
  visibility: visible;
  display: initial;
}
.filled[class*="input-group"] label {
  opacity: 0;
}
.filled[class*="input-group"].active.isFilled label,
.filled[class*="input-group"].active label {
  color: var(--color50);
  opacity: 1;
}
/**END FILLED*/

/*HELPER TEXT*/
.error-helper-text {
  color: var(--red60);
}
/*end*/

/*RIGHT ICON*/
.error-helper-text span[class*="input-action"] {
  color: var(--color60);
}
.filled.error-helper-text span[class*="input-action"] {
  color: var(--color0);
}
.success span[class*="input-action"] {
  color: var(--color60);
}
.filled.success span[class*="input-action"] {
  color: var(--color0);
}
/*-----*/
/*CLEAR BUTTON*/
.clearButton[class*="input-group"]
  > input
  + span[class*="input-action"]
  > button {
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
}
.clearButton[class*="input-group"].isFilled
  span[class*="input-action"]
  > button,
.clearButton[class*="input-group"].active span[class*="input-action"] > button {
  visibility: hidden;
  opacity: 0;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
.clearButton[class*="input-group"].active
  span[class*="input-action"]
  > button[class*="button"],
.clearButton[class*="input-group"].active
  span[class*="input-action"]
  > button[class*="button"]
  > span[class*="icon"] {
  color: var(--color40) !important;
  opacity: 1;
  visibility: visible;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
.filled.clearButton[class*="input-group"]:hover
  span[class*="input-action"]
  > button[class*="button"]
  > span[class*="icon"] {
  color: var(--color5) !important;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
.clearButton[class*="input-group"].active.isFilled
  span[class*="input-action"]
  > button {
  visibility: hidden;
  opacity: 0;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
.clearButton[class*="input-group"].active.isFilled:hover
  span[class*="input-action"]
  > button {
  visibility: visible;
  opacity: 1;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

/*END CLEAR BUTTON*/
