/* BUTTONS TYPES*/
/*--DEFAULT--*/
.default {
	font-size: var(--font_size_button);
	font-family: var(--font_family_button);
	font-weight: bold;
	text-align: center;
	letter-spacing: var(--letter-spacing-button);
	min-width: var(--s40);
	height: var(--s40);
	min-height: unset;
	padding: 0 1rem 0 1rem;
	line-height: var(--s16);
	border-radius: var(--s4);
	text-transform: capitalize;
}
.default.dense {
	font-weight: normal;
	letter-spacing: var(--letter_spacing_body_small);
	height: var(--s28);
	padding: 0 0.75rem 0 0.75rem;
}
/*--card--*/
.card {
	font-size: var(--font_size_caption);
	font-family: var(--font_family_button);
	line-height: var(--s16);
	text-align: center;
	letter-spacing: var(--letter_spacing_caption);
	text-transform: none;
	min-width: var(--s80);
	height: var(--s56);
	display: initial;
	word-break: break-all;
	padding: var(--s8) 0;
	border-radius: var(--s4);
	text-transform: capitalize;
}
.card p {
	margin: 0;
}
.card > span {
	margin-right: 0;
}
.card > span[class*="icon"] {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: min-content;
	margin-bottom: 0.25rem;
}
.card[class*="button"] > div[class*="spinner"] {
	position: initial;
	margin-top: 0.625rem;
}
.dense.card {
	min-width: var(--s56);
	height: var(--s40);
	padding: var(--s6) 0;
	font-size: var(--font_size_caption_small);
	letter-spacing: var(--letter_spacing_caption_small);
}
.dense.card > span[class*="icon"] {
	margin-bottom: 0;
}
.dense.card[class*="button"] > div[class*="spinner"] {
	margin-top: 0.375rem;
}
/*--circle--*/
.circle {
	height: var(--s56);
	width: var(--s56);
	border-radius: 99px;
}

.dense.circle {
	height: var(--s40);
	width: var(--s40);
}
/*--ACTION--*/
.action {
	font-family: var(--font_family_button);
	font-style: normal;
	font-weight: var(--font_weight_h6);
	font-size: var(--font_size_h6);
	letter-spacing: var(--letter_spacing_h6);
	height: var(--s56);
	border-radius: 99px;
	padding: 0.75rem 1rem;
	text-transform: capitalize;
}
.action span {
	margin: 0;
}
.action span:first-child[class*="icon"] {
	text-align: left;
	margin-right: 0.75rem;
}
.action span:last-child[class*="icon"] {
	margin-left: 0.75rem;
}
.dense.action {
	font-weight: normal;
	font-size: var(--font_size_body);
	letter-spacing: var(--letter_spacing_body_bold);
	height: var(--s40);
	border-radius: 99px;
	padding: 0.5rem 0.75rem;
}

.dense.action span:first-child[class*="icon"] {
	margin-right: 0.625rem;
}
.dense.action span:last-child[class*="icon"] {
	margin-left: 0.625rem;
}
/*--ICON--*/
.icon {
	width: var(--s36);
	height: var(--s36);
	min-width: 0;
	min-height: 0;
	border-radius: 99px;
	padding: 0;
}
.dense.icon {
	width: var(--s28);
	height: var(--s28);
}
